import React from "react";
import { connect, router } from "dva";
import "./index.less";
import Icon from "components/Icon";
import "../model";
import $$ from "cmn-utils";
import BaseComponent from "components/BaseComponent";
import { Popover, Badge } from "antd";
import cx from "classnames";
import config from "@/config";
import modelEnhance, { asyncRequest } from "@/utils/modelEnhance";
const { Link } = router;

// 从全局配置里获取参数
const request = config.request || {};
@connect()
export default class MessageNotice extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      unreadMessage: {},
    };
    this.getUnreadMessage();
    $$.on("ReadMessage", () => {
      this.getUnreadMessage();
    });
  }

  componentDidMount() { }

  getUnreadMessage = () => {
    const payload = {
      method: "GET",
      url: "/api/student/student-message/getUnreadMessage",
      afterResponse: (resp) => {
        const { code, data } = resp;
        if (code == 200) {
          this.setState({ unreadMessage: data });
        }
      },
    };
    asyncRequest(payload);
  };

  render() {
    const { theme } = this.props;
    const { unreadMessage } = this.state;
    // 未读消息总数
    const unreadMessageCount =
      unreadMessage.unreadLikeCount +
      unreadMessage.unreadNoticeCount +
      unreadMessage.unreadCommentCount +
      unreadMessage.unreadReplyCount +
      unreadMessage.unreadFollowCount;

    // 未读回复数量：(评论+回复)
    const unreadCommentCount =
      unreadMessage.unreadCommentCount + unreadMessage.unreadReplyCount;
    return (
      <ul className="nav navbar-nav navbar-right clearfix">
        <li className="dropdown">
          <Popover
            placement="bottomRight"
            overlayClassName={cx("navbar-popup", { [theme]: !!theme })}
            content={
              <MessageCenter
                unreadCommentCount={unreadCommentCount}
                unreadMessage={unreadMessage}
              />
            }
            trigger="click"
          >
            <a className="dropdown-toggle">
              消息
              <Badge
                className="message-badge"
                count={unreadMessageCount > 0 ? unreadMessageCount : null}
              ></Badge>
            </a>
          </Popover>
        </li>
      </ul>
    );
  }
}

@connect()
class MessageCenter extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <ul className="dropdown-menu list-group dropdown-persist">
        <li className="list-group-item">
          <Link
            className="animated animated-short fadeInUp"
            to={{ pathname: "/message/center", query: { type: 0 } }}
          >
            <Icon type="LikeTwoTone" antd /> 收到的赞
            {this.props.unreadMessage.unreadLikeCount > 0
              ? "(" + this.props.unreadMessage.unreadLikeCount + ")"
              : null}
          </Link>
        </li>
        <li className="list-group-item">
          <Link
            className="animated animated-short fadeInUp"
            to={{ pathname: "/message/center", query: { type: 1 } }}
          >
            <Icon type="FireTwoTone" antd /> 系统通知
            {this.props.unreadMessage.unreadNoticeCount > 0
              ? "(" + this.props.unreadMessage.unreadNoticeCount + ")"
              : null}
          </Link>
        </li>

        <li className="list-group-item">
          <Link
            className="animated animated-short fadeInUp"
            to={{ pathname: "/message/center", query: { type: 2 } }}
          >
            <Icon type="MessageTwoTone" antd /> 收到的回复
            {this.props.unreadCommentCount > 0
              ? "(" + this.props.unreadCommentCount + ")"
              : null}
          </Link>
        </li>
        <li className="list-group-item">
          <Link
            className="animated animated-short fadeInUp"
            to={{ pathname: "/message/center", query: { type: 3 } }}
          >
            <Icon type="HeartTwoTone" antd /> 查看新粉丝
            {this.props.unreadMessage.unreadFollowCount > 0
              ? "(" + this.props.unreadMessage.unreadFollowCount + ")"
              : null}
          </Link>
        </li>
      </ul>
    );
  }
}
