import { dynamicWrapper, createRoute } from "@/utils/core";

const routesConfig = (app) => ({
  path: "/problem",
  title: "题目",
  component: dynamicWrapper(app, [import("./model")], () =>
    import("./components")
  ),
});

export default (app) => createRoute(app, routesConfig);
