import { dynamicWrapper, createRoute } from "@/utils/core";

const routesConfig = (app) => ({
  path: "/userInfo",
  title: "个人中心",
  component: dynamicWrapper(
    app,
    [import("./model"), import("../Login/model")],
    () => import("./components")
  ),
});

export default (app) => createRoute(app, routesConfig);
