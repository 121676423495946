const CryptoJS = require("crypto-js");

export function encrypt(word) {
  const key = CryptoJS.enc.Utf8.parse("29582849bondmall");
  const src = CryptoJS.enc.Utf8.parse(word);
  const encrypted = CryptoJS.AES.encrypt(src, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });
  return encrypted.toString();
}

export function decrypt(word) {
  const key = CryptoJS.enc.Utf8.parse("29582849bondmall");
  const decrypt = CryptoJS.AES.decrypt(word, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });
  return CryptoJS.enc.Utf8.stringify(decrypt).toString();
}

export function getBaseurl() {
  const url = window.location.href;
  let x = url.indexOf("/");
  for (let i = 0; i < 2; i++) {
    x = url.indexOf("/", x + 1);
  }
  return url.substr(0, x);
}

export function getBaseEditorUrl() {
  // 测试环境
  // return "http://139.9.204.180:8888"
  // 生产环境
  return "https://py.1code.pro";
}

export function getFlowBaseUrl() {
  // 测试环境
  // return "http://192.168.50.211:8087";
  // 生产环境
  return "https://draw.onecode.pro";
}

export function getQueryVariableMap() {
  const query = window.location.href.split("?").pop();
  const vars = query.split("&");
  const variableMap = {};
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split("=");
    variableMap[pair[0]] = pair[1];
  }
  return variableMap;
}

//是否全屏
export function isFullscreen() {
  return (
    document.fullscreenElement ||
    document.msFullscreenElement ||
    document.mozFullScreenElement ||
    document.webkitFullscreenElement ||
    false
  );
}

//请求全屏
window.launchIntoFullscreen = function (element) {
  if (element.requestFullscreen) {
    element.requestFullscreen();
  } else if (element.mozRequestFullScreen) {
    element.mozRequestFullScreen();
  } else if (element.webkitRequestFullscreen) {
    element.webkitRequestFullscreen();
  } else if (element.msRequestFullscreen) {
    element.msRequestFullscreen();
  }
};

//退出全屏
window.exitFullscreen = function () {
  if (window.document.exitFullscreen) {
    window.document.exitFullscreen();
  } else if (document.mozCancelFullScreen) {
    window.document.mozCancelFullScreen();
  } else if (document.webkitExitFullscreen) {
    window.document.webkitExitFullscreen();
  }
};

export function getOsType() {
  const system = {
    isWin: false,
    isMac: false,
    isXll: false,
    isIpad: false,
  };
  const platform = navigator.platform;
  system.isWin = platform.indexOf("Win") === 0;
  system.isMac = platform.indexOf("Mac") === 0;
  system.isXll = platform === "X11" || platform.indexOf("Linux") === 0;
  system.isIpad = navigator.userAgent.match(/iPad/i) !== null;
  return system;
}
