import modelEnhance from "@/utils/modelEnhance";

export default modelEnhance({
  namespace: "cost",
  effects: {
    *getCostConfig({ payload, success }, { call, put }) {
      yield put({
        type: "@request",
        payload: {
          url: "/api/system/costConfig/list",
        },
        afterResponse: (resp) => {
          const { code, data } = resp;
          if (code == 200) {
            success(data);
          }
        },
      });
    },
  },
});
