import { createRoutes } from "@/utils/core";
import BasicLayout from "@/layouts/BasicLayout";
import UserLayout from "@/layouts/UserLayout";
import Page403 from "./Pages/403";
import NotFound from "./Pages/404";
import Page500 from "./Pages/500";
import ScreenLock from "./Widgets/ScreenLock";
import Coming from "./Widgets/Coming";
import Gallery from "./Widgets/Gallery";
import Result from "./Widgets/Result";
import LevelRoute from "./Widgets/LevelRoute";
import Login from "./Login";
import Dashboard from "./Dashboard";
import Home from "./Home";
import Blank from "./Blank";
import Toolbar from "./Widgets/Toolbar";
import BaseComponent from "./Widgets/BaseComponent";
import Column from "./Widgets/Column";
import TransferTree from "./Widgets/TransferTree";
import SearchBar from "./Widgets/SearchBar";
import DataTable from "./Widgets/DataTable";
import Form from "./Widgets/Form";
import EC from "./Widgets/Charts/EC";
import G2 from "./Widgets/Charts/G2";
import Print from "./Widgets/Print";
import Banner from "./Widgets/Banner";
import Icon from "./UI/Icon";
import Mask from "./UI/Mask";
import Editor from "./UI/Editor";
import CSSAnimate from "./UI/CSSAnimate";
import Alerts from "./UI/Alerts";
import Button from "./UI/Button";
import Modal from "./UI/Modal";
import CRUD from "./Business/CRUD";
import CRUDDetail from "./Business/CRUD/routers/Detail";
import Image from "./UI/Image";
import CourseDetail from "./CourseDetail";
import WorkDetail from "./WorkDetail";
import RaceDetail from "./RaceDetail";
import ProjectDetail from "./ProjectDetail";
import Assessment from "./Assessment";
import RaceCourse from "./RaceCourse";
import UserInfo from "./UserInfo";
import PlayerPage from "./PlayerPage";
import MessageCenter from "./MessageCenter";
import SignIn from "./SignIn";
import HomePage from "./HomePage/index";
import problem from "./problem/index";
import CourseShop from "./CourseShop/index";
import CourseShopDetail from "./CourseShopDetail/index";
import PersonalHomePage from "./PersonalHomePage/index";
import MessageNotice from "./MessageNotice/index";
import Cost from "./Cost/index";
import ChatPages from "./ChatPages/index";

var _hmt = _hmt || [];
(function () {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?e306912858b831bc13228996cf14c3f7";
  var s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(hm, s);
})();

/**
 * 主路由配置
 *
 * path 路由地址
 * component 组件
 * indexRoute 默认显示路由
 * childRoutes 所有子路由
 * NotFound 路由要放到最下面，当所有路由当没匹配到时会进入这个页面
 */
const routesConfig = (app) => [
  {
    path: "/sign",
    title: "登录",
    indexRoute: "/sign/login",
    component: UserLayout,
    childRoutes: [Login(app), NotFound()],
  },
  {
    path: "/",
    title: "系统中心",
    component: BasicLayout,
    indexRoute: "/homePage",
    childRoutes: [
      Home(app),
      ChatPages(app), 
      HomePage(app),
      problem(app),
      SignIn(app),
      Dashboard(app),
      Blank(app),
      Toolbar(app),
      Column(),
      SearchBar(),
      EC(app),
      G2(app),
      Icon(),
      Mask(),
      Editor(),
      CSSAnimate(),
      Alerts(),
      Button(),
      Modal(),
      DataTable(app),
      Form(app),
      TransferTree(app),
      BaseComponent(),
      CRUD(app),
      CRUDDetail(app),
      Coming(),
      ScreenLock(),
      Gallery(),
      Result(),
      Page403(),
      Page500(),
      Print(),
      Banner(app),
      LevelRoute(app),
      Image(),
      CourseDetail(app),
      CourseShop(app),
      CourseShopDetail(app),
      WorkDetail(app),
      RaceDetail(app),
      ProjectDetail(app),
      Assessment(app),
      RaceCourse(app),
      UserInfo(app),
      PlayerPage(app),
      MessageCenter(app),
      PersonalHomePage(app),
      MessageNotice(app),
      Cost(app),
      NotFound(),
    ],
  },
];

export default (app) => createRoutes(app, routesConfig);
