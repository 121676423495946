import { dynamicWrapper, createRoute } from "@/utils/core";

const routesConfig = (app) => ({
  path: "/cost",
  title: "续费页面",
  component: dynamicWrapper(app, [import("./model")], () =>
    import("./components")
  ),
});

export default (app) => createRoute(app, routesConfig);
