import { dynamicWrapper, createRoute } from "@/utils/core";

const routesConfig = (app) => ({
  path: "/courseShopDetail",
  title: "商城课程详情",
  component: dynamicWrapper(app, [import("./model")], () =>
    import("./components")
  ),
});

export default (app) => createRoute(app, routesConfig);
