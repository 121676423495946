import modelEnhance from "@/utils/modelEnhance";

export default modelEnhance({
  namespace: "messageNotice",

  effects: {
    *getUnreadMessage({ payload, success }, { call, put }) {
      yield put({
        type: "@request",
        payload: {
          method: "GET",
          url: "/api/student/student-message/getUnreadMessage",
        },
        afterResponse: (resp) => {
          const { code, data } = resp;
          if (code == 200) {
            success(data);
          }
        },
      });
    },
  },
});
