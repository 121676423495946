import { dynamicWrapper, createRoute } from "@/utils/core";

const routesConfig = (app) => ({
  path: "/chatPages",
  title: "智能助手",
  component: dynamicWrapper(app, [import("./model")], () =>
    import("./components")
  ),
});

export default (app) => createRoute(app, routesConfig);
