import React from "react";
import { connect, router, routerRedux } from "dva";
import { Layout } from "antd";
import NavBar from "components/NavBar";
import pathToRegexp from "path-to-regexp";
import { enquireIsMobile, enquireIsTablet } from "@/utils/enquireScreen";
import $$ from "cmn-utils";
import cx from "classnames";
import isEqual from "react-fast-compare";
import "assets/styles/transition.less";
import "./styles/basic.less";
import { PopupTeacher, MessageCenter } from "@/components/Popup";
const { Switch } = router;
const { Content, Header, Footer } = Layout;

/**
 * 基本部局
 * 可设置多种皮肤 theme: [light, grey, primary, info, warning, danger, alert, system, success, dark]
 * 可设置多种布局 [header(固定头), sidebar(固定边栏), breadcrumb(固定面包蟹), tabLayout(标签布局)]
 * @author weiq
 */
@connect(({ global }) => ({ global }))
export default class BasicLayout extends React.PureComponent {
  constructor(props) {
    super(props);
    const theme = $$.getStore("theme", {
      leftSide: "darkgrey", // 左边
      navbar: "light", // 顶部
    });
    if (!theme.layout) {
      theme.layout = ["fixedHeader", "fixedSidebar", "fixedBreadcrumbs"];
    }
    const user = $$.getStore("user", []);
    this.state = {
      collapsedLeftSide: false, // 左边栏开关控制
      leftCollapsedWidth: 60, // 左边栏宽度
      expandTopBar: false, // 头部多功能区开合
      showSidebarHeader: false, // 左边栏头部开关
      collapsedRightSide: true, // 右边栏开关
      theme, // 皮肤设置
      currentMenu: {},
      isMobile: false,
      isPad: false,
      user,
      showGirls: true
    };
    $$.on("UpdateTheHead", () => {
      const user = $$.getStore("user", []);
      this.setState({ user });
    });
    $$.on("HideGrils", () => {
      this.setState({ showGirls: false });
    });
    $$.on("ShowGrils", () => {
      this.setState({ showGirls: true });
    });
    props.dispatch({
      type: "global/getMenu",
    });
  }

  componentDidMount() {
    this.checkLoginState();

    this.unregisterEnquireMobile = enquireIsMobile((ismobile) => {
      console.log("ismobile:" + ismobile);
      const { isMobile, theme } = this.state;
      if (isMobile !== ismobile) {
        // 如查是移动端则不固定侧边栏
        if (ismobile && $$.isArray(theme.layout)) {
          theme.layout = theme.layout.filter((item) => item !== "fixedSidebar");
        }
        this.setState({
          isMobile: ismobile,
        });
      }
    });

    this.unregisterEnquirePad = enquireIsTablet((isPad) => {
      $$.setStore("isPad", isPad);
      this.setState({
        isPad: isPad,
      });
    });
  }

  // 检查有户是否登录
  checkLoginState() {
    const token = $$.getStore("token");
    if (!token) {
      this.props.dispatch(routerRedux.replace("/sign/login"));
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location.pathname == "/home") {
      this.$NavBar.setTabVisible(true);
      $$.getStore("activeIndex") === -1 && $$.setStore("activeIndex", 0);
    } else {
      this.$NavBar.setTabVisible(false);
      $$.setStore("activeIndex", -1);
    }
    if (
      !isEqual(this.props.location.pathname, prevProps.location.pathname) ||
      !isEqual(this.props.global.flatMenu, prevProps.global.flatMenu)
    ) {
      this.setState({
        currentMenu: this.getCurrentMenu(this.props) || {},
      });
    }
  }

  componentWillUnmount() {
    // 清理监听
    this.unregisterEnquirePad();
    this.unregisterEnquireMobile();
  }

  getCurrentMenu(props) {
    const {
      location: { pathname },
      global,
    } = props || this.props;
    const menu = this.getMeunMatchKeys(global.flatMenu, pathname)[0];
    return menu;
  }

  getMeunMatchKeys = (flatMenu, path) => {
    return flatMenu.filter((item) => {
      return pathToRegexp(item.path).test(path);
    });
  };

  /**
   * 顶部左侧菜单图标收缩控制
   */
  onCollapseLeftSide = (_) => {
    const collapsedLeftSide =
      this.state.leftCollapsedWidth === 0
        ? true
        : !this.state.collapsedLeftSide;
    const collapsedRightSide =
      this.state.collapsedRightSide || !collapsedLeftSide;

    this.setState({
      collapsedLeftSide,
      collapsedRightSide,
      leftCollapsedWidth: 60,
    });
  };

  /**
   * 完全关闭左边栏，即宽为0
   */
  onCollapseLeftSideAll = (_) => {
    this.setState({
      collapsedLeftSide: true,
      leftCollapsedWidth: 0,
    });
  };

  /**
   * 展开面包屑所在条中的多功能区
   */
  onExpandTopBar = (_) => {
    this.setState({
      expandTopBar: true,
    });
  };

  /**
   * 与上面相反
   */
  onCollapseTopBar = (_) => {
    this.setState({
      expandTopBar: false,
    });
  };

  /**
   * 切换左边栏中头部的开合
   */
  toggleSidebarHeader = (_) => {
    this.setState({
      showSidebarHeader: !this.state.showSidebarHeader,
    });
  };

  /**
   * 切换右边栏
   */
  toggleRightSide = (_) => {
    const { collapsedLeftSide, collapsedRightSide } = this.state;
    this.setState({
      collapsedLeftSide: collapsedRightSide ? true : collapsedLeftSide,
      collapsedRightSide: !collapsedRightSide,
    });
  };

  onChangeTheme = (theme) => {
    $$.setStore("theme", theme);
    this.setState({
      theme,
    });
  };

  render() {
    const { collapsedLeftSide, theme, user, isMobile, showGirls } = this.state;
    const { routerData, global } = this.props;
    const { menu, flatMenu } = global;
    const { childRoutes } = routerData;
    const classnames = cx("basic-layout", "full-layout", {
      fixed: theme.layout && theme.layout.indexOf("fixedSidebar") !== -1,
      "fixed-header":
        theme.layout && theme.layout.indexOf("fixedHeader") !== -1,
      "fixed-breadcrumbs":
        theme.layout && theme.layout.indexOf("fixedBreadcrumbs") !== -1,
      "hided-breadcrumbs":
        theme.layout && theme.layout.indexOf("hidedBreadcrumbs") !== -1,
    });
    return (
      <Layout className={classnames}>
        <Header>
          <NavBar
            collapsed={collapsedLeftSide}
            onCollapseLeftSide={this.onCollapseLeftSide}
            onExpandTopBar={this.onExpandTopBar}
            toggleSidebarHeader={this.toggleSidebarHeader}
            theme={"dark"}
            user={user}
            isMobile={isMobile}
            onRef={(ref) => {
              this.$NavBar = ref;
            }}
          />
        </Header>
        <Layout>
          <Content className="router-page">
            {showGirls ? <>
              <PopupTeacher />
              <MessageCenter />
            </> : <></>}

            <Switch>{childRoutes}</Switch>
          </Content>
        </Layout>
      </Layout>
    );
  }
}
