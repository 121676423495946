import React, { Component } from "react";
import Icon from "../Icon";
import { Popover, Badge, Avatar } from "antd";
import { router } from "dva";
import cx from "classnames";
import "./style/index.less";
import logoImg from "assets/images/logo.png";
import SearchBox from "./SearchBox";
import $$ from "cmn-utils";
import MessageNotice from "@/routes/MessageNotice/components";
import { getBaseurl } from "@/utils/common";
import Cost from "@/routes/Cost/components/index";
import { Modal } from "antd";
const { Link } = router;

/**
 * 其本本局头部区域
 */
class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openSearchBox: false,
      visible: true,
    };
    if (props.onRef) {
      props.onRef(this);
    }
  }
  static defaultProps = {
    fixed: true,
    theme: "", //'bg-dark',
  };

  toggleFullScreen() {
    if (
      !document.fullscreenElement &&
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement &&
      !document.msFullscreenElement
    ) {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.msRequestFullscreen) {
        document.documentElement.msRequestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    }
  }

  handleCancel = () => {
    this.setState({ showRenewalModal: false });
  };

  showRenewal = () => {
    this.setState({ showRenewalModal: true });
  };

  setTabVisible = (visible) => {
    if (visible != this.state.visible) {
      this.setState({
        visible: visible,
      });
    }
  };

  gotoScratch() {
    sessionStorage.removeItem("coursePlan");
    sessionStorage.removeItem("raceScratchData");
    const path = getBaseurl() + "/scratch/index.html";
    window.location.href = path;
  }

  gotoPython() {
    sessionStorage.removeItem("coursePlan");
    const path = getBaseurl() + "/python/index.html";
    window.location.href = path;
  }

  gotoScratchJR() {
    const path = getBaseurl() + "/scratchjr/index.html";
    window.location.href = path;
  }

  onCloseSearchBox = () => {
    this.setState({
      openSearchBox: false,
    });
  };

  onOpenSearchBox = () => {
    this.setState({
      openSearchBox: true,
    });
  };

  render() {
    const { openSearchBox, visible } = this.state;
    const { fixed, theme, collapsed, user, isMobile } = this.props;
    const classnames = cx("navbar", {
      "navbar-fixed-top": !!fixed,
      "navbar-sm": isMobile ? true : collapsed,
      ["bg-" + theme]: !!theme,
    });

    const selectBannerIndex = $$.getStore("activeIndex");
    const bannerList = ["作品中心", "课程中心", "赛事中心", "刷题通关"];

    let showLink = true;
    if (visible && !$$.getStore("isPad")) {
      showLink = false;
    }
    return (
      <header className={classnames}>
        <div className="navbar-branding">
          <Link className="navbar-brand" to="/homePage">
            <img src={logoImg} alt="logo" />
            <b>华儿街OneCode</b>
          </Link>
        </div>
        {
          <div>
            {bannerList.map((banner, index) => {
              return (
                <div
                  className="navbar-branding"
                  style={{ width: "100px" }}
                  key={index}
                >
                  <Link
                    className="navbar-brand"
                    to={"/home"}
                    onClick={() => {
                      $$.setStore("activeIndex", index);
                    }}
                  >
                    <b
                      className={
                        selectBannerIndex == index ? "brand-selected" : ""
                      }
                    >
                      {banner}
                    </b>
                  </Link>
                </div>
              );
            })}
          </div>
        }

        <ul className="nav navbar-nav navbar-right clearfix">
          <li className="dropdown">
            <Popover
              placement="bottomRight"
              title={`WELCOME ${user.studentName}`}
              overlayClassName={cx("navbar-popup", { [theme]: !!theme })}
              content={<UserDropDown showRenewal={this.showRenewal}/>}
              trigger="click"
            >
              <a className="dropdown-toggle">
                <Badge>
                  <Avatar
                    src={
                      user.studentHead
                        ? user.studentHead
                        : require("assets/images/avatar.jpg")
                    }
                  >
                    {user.studentName}
                  </Avatar>
                </Badge>
              </a>
            </Popover>
          </li>
        </ul>
        <Modal
              visible={this.state.showRenewalModal}
              title={null}
              onCancel={this.handleCancel}
              footer={null}
              destroyOnClose={true}
              mask={true}
              bodyStyle={{ backgroundColor: '#fffcf5', borderRadius: '16px' }}
            >
              <Cost />
            </Modal>
        <MessageNotice theme={theme} />
        <ul className="code_center nav navbar-nav navbar-right clearfix">
          <li className="dropdown">
            <Popover
              placement="bottomRight"
              overlayClassName={cx("navbar-popup", { [theme]: !!theme })}
              content={
                <ul className="dropdown-menu list-group dropdown-persist">
                  <li
                    className="list-group-item code_center_menu2"
                    onClick={() => this.gotoScratch()}
                  >
                    <div>Scratch</div>
                  </li>
                  <li
                    className="list-group-item code_center_menu2"
                    onClick={() => this.gotoPython()}
                  >
                    <div>Python</div>
                  </li>
                  <li
                    className="list-group-item code_center_menu2"
                    onClick={() => this.gotoScratchJR()}
                  >
                    <div>ScratchJR</div>
                  </li>
                  <li
                    className="list-group-item code_center_menu2"
                    onClick={() => window.open("https://lightly.teamcode.com/")}
                  >
                    <div>C++</div>
                  </li>
                </ul>
              }
              trigger="click"
            >
              <a className="dropdown-toggle">在线编程</a>
            </Popover>
          </li>
        </ul>
        <SearchBox visible={openSearchBox} onClose={this.onCloseSearchBox} />
      </header>
    );
  }
}

const UserDropDown = (props) => (
  <ul className="dropdown-menu list-group dropdown-persist">
    <li className="list-group-item">
      <Link className="animated animated-short fadeInUp" to="/userInfo">
        <Icon type="gear" /> 个人中心
      </Link>
    </li>
    <li className="list-group-item">
      <Link className="animated animated-short fadeInUp" to="/signIn">
        <Icon type="camera" /> 签到打卡
      </Link>
    </li>
    <li className="list-group-item">
      <Link className="animated animated-short fadeInUp" to="#" onClick={props.showRenewal}>
      <Icon type="MoneyCollectOutlined" antd={true}/> VIP续期
      </Link>
    </li>
    <li className="list-group-item dropdown-footer">
      <Link to="/sign/login">
        <Icon type="poweroff" /> 退出
      </Link>
    </li>
  </ul>
);

export default NavBar;
