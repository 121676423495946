import React, { Component } from "react";
import deleteImg from "@/assets/images/delete.jpg";
import teacherWeChat from "@/assets/images/teacherWeChatT.record_dat";
import { connect, routerRedux } from "dva";
import "./index.less";
import Draggable from "react-draggable";

class TeacherTip extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="TeacherTipMask">
        <div className="TeacherTipContent">
          <div className="TeacherTipDeleteImg">
            <img
              src={deleteImg}
              onClick={(e) => {
                this.props?.changeState(e);
              }}
            />
          </div>
          <div className="TeacherTipWeChat">
            <img src={teacherWeChat} />
            <div className="TeacherTipsFont">官方客服微信号</div>
          </div>
          <div className="TeacherTipsFont">欢迎扫码进行反馈</div>
        </div>
      </div>
    );
  }
}
@connect()
class PopupTeacher extends Component {
  constructor(props) {
    super(props);
    this.popupTeacherDev = React.createRef();
    this.state = {
      showState: false,
    };
  }
  changeState(e) {
    this.setState({ showState: !this.state.showState });
    e.stopPropagation();
  }
  goToGpt = () => {
    const { dispatch } = this.props;
    dispatch(routerRedux.push({ pathname: "/chatPages" }));
  }
  render() {
    return (
      <>
        {this.state.showState ? (
          <TeacherTip changeState={this.changeState.bind(this)} />
        ) : null}
        <Draggable handle=".popupTeacherImg">
          <div className="popupTeacher">
            <div className="popupTeacherContent">
              <div
                className="concatTeacher"
                onClick={this.goToGpt}
              />
              <div className="popupTeacherImg" />
            </div>
          </div>
        </Draggable>
      </>
    );
  }
}

@connect()
class MessageCenter extends Component {
  goToMessageCenter = () => {
    const { dispatch } = this.props;
    dispatch(routerRedux.push({ pathname: "/message/center" }));
  };
  render() {
    return (
      <>
        <Draggable handle=".MessageCenterRobotImg">
          <div className="MessageCenter">
            <div>
              <div
                className="MessageCenterDescImg"
                onClick={this.goToMessageCenter}
              />
              <div className="MessageCenterRobotImg" />
            </div>
          </div>
        </Draggable>
      </>
    );
  }
}

export { PopupTeacher, MessageCenter };
