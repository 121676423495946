import React from "react";
import { connect, routerRedux } from "dva";
import "./index.less";
import '../model/index'
import QRCode from "qrcode.react";
import wx from "@/assets/images/wx.avif";
import $$ from "cmn-utils";
import moment from "moment";
import logo from "@/assets/images/logo.png";
import { normal } from "components/Notification";

let staticRenewalConfig = []

const discountMap = new Map([
  [1, 5],
  [2, 15],
  [3, 60],
])

@connect()
export default class Cost extends React.Component {

  constructor(props) {
    super(props);
    const userInfo = $$.getStore("user", []);
    this.intervalTimer = React.createRef();
    const todayStart = moment().startOf('day');
    // 若用户vip结束时间小于今天则从今天开始算起
    const costBeginDate = moment(userInfo.vipExpirationDate).isBefore(todayStart) ? todayStart : moment(userInfo.vipExpirationDate)
    this.state = {
      user: userInfo,
      costTypeIndex: 1,
      accountTypeIndex: 1,
      discountPrice: 5, // 优惠金额
      costBeginDate: moment(costBeginDate).format("yyyy-MM-DD"),
      renewalDate: moment(costBeginDate).add(1, 'month').format("yyyy-MM-DD")
    };
    this.getCostConfig();
    this.showQrCode(1, 1);
  }

  componentWillUnmount() {
    console.log("componentWillUnmount清空定时器");
    // 清空定时器!!!
    window.clearInterval(this.intervalTimer.current);
  }


  // 获取续费配置信息
  getCostConfig = () => {
    $$.get('/api/system/costConfig/getCostConfig')
      .then((resp) => {
        if (resp.code == 200) {
          staticRenewalConfig = resp.data
          const firstConfig = staticRenewalConfig.filter(item => item.accountType == 1)[0]
          this.setState({ renewalConfig: firstConfig, realPrice: firstConfig.renewalMonthPrice })
        }
      })
      .catch((e) => console.error(e));
  }

  // 展示二维码
  showQrCode = (accountType, renewalType, price, month) => {
    if (price) {
      this.setState({
        realPrice: price
      });
    }
    if (month) {
      this.setState({
        renewalDate: moment(this.state.costBeginDate).add(month, 'month').format("yyyy-MM-DD")
      })
    }
    $$.get('/api/student/student-renewal/renewal/' + accountType + '/' + renewalType)
      .then((resp) => {
        if (resp.code == 200) {
          this.setState({
            buyRes: resp.data
          });

          // 设置定时任务查询订单状态,购买成功后直接跳转页面
          this.intervalTimer.current && clearInterval(this.intervalTimer.current)
          this.intervalTimer.current = window.setInterval(() => {
            const that = this
            const url = '/api/business/order/getOrderStatus/' + resp.data.orderNo
            $$.get(url)
              .then((resp) => {
                if (resp.code == 200 && resp.data === 2) {
                  normal.info(
                    "续期成功"
                  );
                  setTimeout(function () {
                    that.refreshUser();
                  }, 1000)
                }
              })
              .catch((e) => console.error(e));
          }, 3000);
        }
      })
      .catch((e) => console.error(e));
  }

  // 初始化用户
  refreshUser() {
    let token = localStorage.getItem("token");
    token = token ? token.replaceAll('"', "") : token;
    $$.post('/api/student/student/getInfo', {
      async: false,
      headers: {
        'Authorization': token
      }
    })
      .then((resp) => {
        if (resp.code == 200) {
          $$.setStore("user", resp.data);
          window.location.reload();
        }
      })
      .catch((e) => console.error(e));
  }


  // 续费类型变更
  changeCost = (renewalType, price, month) => {
    this.setState({ costTypeIndex: renewalType, discountPrice: discountMap.get(renewalType) })
    this.showQrCode(this.state.renewalConfig.accountType, renewalType, price, month)
  }

  // 账号类型变更
  changeType = (accountType) => {
    const accountChecked = staticRenewalConfig.filter(item => item.accountType == accountType)[0]
    this.setState({
      costTypeIndex: 1, accountTypeIndex: accountType,
      renewalConfig: accountChecked, discountPrice: discountMap.get(1)
    })
    this.showQrCode(accountType, 1, accountChecked.renewalMonthPrice, 1)
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  render() {
    return (
      <div id="checkout_counter_popup">
        <div className="main_info">
          <div className="main_header">
            <div className="member_info">
              <img className="login_icon" src={this.state.user?.studentHead} alt="个人头像" />
              <div className="login_info">
                <p><span className="login_name">{this.state.user?.studentName}</span></p>
              </div>
            </div>
            <div className="member_time_info">
              <p className="descriptive_time_content">订阅日期</p>
              <p className="current_time_info"><span>订阅前：</span><span>{this.state.user ? this.state.costBeginDate : ''}</span></p>
              <p className="future_time_info"><span>订阅后：</span><span>{this.state.user ? this.state.renewalDate : ''}</span></p>
            </div>
          </div>

          <ul id="vip_types">
            <li className={this.state.accountTypeIndex == 1 ? "oj_type_item_selected" : "oj_type_item"} data-index="0" onClick={() => this.changeType(1)}>
              <h5>学习平台</h5>
              <p>在线编程平台</p>
            </li>
            <li className={this.state.accountTypeIndex == 2 ? "oj_type_item_selected" : "oj_type_item"} data-index="1" onClick={() => this.changeType(2)}>
              <h5>OJ平台</h5>
              <p>在线练习平台</p>
            </li>
            <li className={this.state.accountTypeIndex == 3 ? "oj_type_item_selected" : "oj_type_item"} data-index="1" onClick={() => this.changeType(3)}>
              <h5>OJ&学习</h5>
              <p>在线教学平台</p>
            </li>
          </ul>

          <div className="detai_container">
            <div className="items_container">
              <ul className="item_study">
                <li className={this.state.costTypeIndex == 1 ? "item_study_li_selected" : "item_study_li"} onClick={() => this.changeCost(1, this.state.renewalConfig.renewalMonthPrice, 1)}>
                  <span className="item_badge">一月</span>
                  <h5>月卡</h5>
                  <div>
                    <span>￥</span>
                    <span>{this.state.renewalConfig?.renewalMonthPrice}</span>
                  </div>
                </li>
                <li className={this.state.costTypeIndex == 2 ? "item_study_li_selected" : "item_study_li"} onClick={() => this.changeCost(2, this.state.renewalConfig.renewalSeasonPrice, 3)}>
                  <span className="item_badge">一季</span>
                  <h5>季卡</h5>
                  <div>
                    <span>￥</span>
                    <span>{this.state.renewalConfig?.renewalSeasonPrice}</span>
                  </div>
                </li>
                <li className={this.state.costTypeIndex == 3 ? "item_study_li_selected" : "item_study_li"} onClick={() => this.changeCost(3, this.state.renewalConfig.renewalYearPrice, 12)}>
                  <span className="item_badge">一年</span>
                  <h5>年卡</h5>
                  <div>
                    <span>￥</span>
                    <span>{this.state.renewalConfig?.renewalYearPrice}</span>
                  </div>
                </li>
              </ul>

            </div>
          </div>

          <div className="payment_info_tips">
            <ul>
              <li>年卡365天 | 季卡90天 | 月卡30天</li>
              <li>订阅时间累积叠加</li>
            </ul>
          </div>
        </div>

        <div className="pay_container">
          <div className="pay_arrow"></div>
          <div className="form_main">
            <div className="pay_price">
              <span className="price_label">支付</span>
              <span className="price_unit">￥</span>
              <span className="price_final_prize">{this.state.realPrice}</span>
            </div>
            <div className="pay_promotion_container">
              <div className="promotion">
                <span>已减 ¥{this.state.discountPrice}</span>
              </div>
            </div>
            <div className="pay_qrcode_img">
              <QRCode
                value={this.state.buyRes?.url}
                imageSettings={{
                  src: logo,
                  excavate: true,
                  width: 18,
                  height: 18,
                }}
              />
            </div>
            <p className="pay_option_txt">
              <img src={wx} />
              <span>仅支持微信付款</span>
            </p>
            <div className="pay_agreement">
              <p>开通前请阅读</p>
              <p><a href="#">《平台会员服务协议》</a></p>
              <p><a href="#">《付款授权服务协议》</a></p>
            </div>
          </div>
        </div>
      </div >
    );
  }
}
